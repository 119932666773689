// Color system
$gray-600: #75767b;
$gray-800: #373737;
$black: #000;

$danger: #f00;


// Spacing
$spacer: 1.25rem;


// Body
$body-color: $black;


// Links
$link-color: #e0af1d;
$link-hover-color: #444;


// Typography
$font-family-sans-serif: Arial, sans-serif;

$font-size-base: .8125rem;

$line-height-base: 1.75;

$lead-font-size: 2.25rem;