/*------------------------------------------------------------------------------------------------------------------------------------------------
    normalize.css 
------------------------------------------------------------------------------------------------------------------------------------------------*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}


a {
    &:focus {
        outline: thin dotted;
    }

    &:active,
    &:hover {
        outline: 0;
    }
}

h1 {
    font-size: 2em;
    margin: .67em 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

hr {
    box-sizing: content-box;
    height: 0;
}

mark {
    background: #ff0;
    color: $black;
}

code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 1em;
}

pre {
    white-space: pre-wrap;
}

q {
    quotes: "\201C""\201D""\2018""\2019";
}

small {
    font-size: 80%;
}

sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -.5em;
}

sub {
    bottom: -.25em;
}

img {
    border: 0;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: .35em .625em .75em;
}

legend {
    border: 0;
    padding: 0;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    box-sizing: border-box;
}

button,
input {
    line-height: normal;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"] {
    cursor: pointer;
}

input {

    &[type="reset"],
    &[type="submit"] {
        cursor: pointer;
    }
}

button[disabled],
html input[disabled] {
    cursor: default;
}

input {

    &[type="checkbox"],
    &[type="radio"] {
        box-sizing: border-box;
        padding: 0;
    }

    &[type="search"] {
        box-sizing: content-box;
    }
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

p {
    margin: 0;
    zoom: 1;
}

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

em {
    font-style: italic;
}

.aligncenter {
    display: table;
    text-align: center;
    margin: 0 auto;
}

.alignleft {
    float: left;
    display: inline;
}

.alignright {
    float: right;
    display: inline;
}

iframe {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    border: 0;
    outline: 0;
}

dl,
menu,
ol,
ul {
    margin: 1em 0;
}

dd {
    margin: 0 0 0 40px;
}

menu,
ol,
ul {
    padding: 0;
}

nav {

    ul,
    ol {
        list-style: none;
        list-style-image: none;
    }
}