$bgGray: #1A1A24;
$bgBlue: #191921;
$bgDarkRed: #321D21;
$panelGray: #323240;

$gradientRedBlue: linear-gradient(270deg, $blue 0%, transparent 100%);
$gradientRedBlueHover: linear-gradient(270deg, $blue 0%, $blue 100%);

$blue: #00B7C6;
$red: #C5334F;
$darkRed: #832C3C;
$green: #22464E;

$fontInter: 'Inter',
sans-serif;

$breakpoint-down-xxl: 1920px;
$breakpoint-up-xl: 1365px;
$breakpoint-up-lg: 1199px;
$breakpoint-down-md: 1024px;
$breakpoint-up-md: 1023px;
$breakpoint-up-sm: 767px;
$breakpoint-up-xs: 575px;
$breakpoint-up-xxs: 479px;
$breakpoint-up-xxxs: 359px;

body {
    background-color: $bgGray;
    color: white;
    font-size: 1.375rem;
    line-height: 1.875rem;
    padding: 0;
    margin: 0;
    font-family: 'Figtree', sans-serif;

    &.home {
        background-image: url('../../assets/images/graphic-top.png');
        background-size: 68%;
        background-position: right 4rem;
        background-repeat: no-repeat;
    }

    a {
        color: white;
        text-decoration: none;
    }

    a,
    a:active,
    a:focus {
        outline: none;
    }

}

body.home {
    background-image: none;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: border-box;
    shape-rendering: auto;
}

button,
.button-link {
    border: 0;
    border-radius: 100px;
    color: white;
    font-size: 1.125rem;
    padding: 0.75rem 3.5rem;
    transition: all 0.3s ease;

    &.primary {
        background: $gradientRedBlue;
        background-color: $red;
        background-size: 150%;

        &:hover {
            background-position: 100%;
        }
    }
}

h1,
h2,
h3,
h4 {
    color: white;
    margin: 0 0 1rem;
    line-height: 1.2;

    span {
        color: $blue;
    }
}

h1 {
    font-size: 4rem;
    padding-bottom: 2rem;
}

h2 {
    font-size: 4rem;
    margin-bottom: 0;
}

p {
    color: #D0D0D0;
    font-weight: 300;
    margin: 0 0 2rem;
}

@media screen and (max-width: $breakpoint-up-md) {
    h2 {
        font-size: 3rem;
    }

    p {
        font-size: 1.2rem;
    }
}

.container-404 {
    margin: 100px 30px 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.block-header {
    background: rgba(0, 0, 0, 0);

    &.sticky {
        position: fixed;
        top: 0;
        width: 100%;
        background: rgba(0, 0, 0, 1) !important;
        transition: all 0.7s ease;
        z-index: 9999;

        * {
            transition: all 0.7s ease;
        }

        .container-nav {
            padding: 0.5rem 2rem !important;

            .logo {
                img {
                    max-width: 100px !important;
                }
            }
        }
    }

    &.sticky+.block-top {
        padding-top: 102px;
    }

    .container-nav {
        padding: 1.5rem 4rem;
        display: flex;
        align-items: flex-end;
        gap: 100px;

        .logo {
            padding-top: 0.5rem;

            img {
                transition: all 0.3s ease;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        nav {
            display: flex;
            gap: 2rem;
            height: 35px;
        }

        .main-nav-holder {
            transition: 0.2s;
        }

        .main-nav {
            display: flex;
            margin: 0;

            >li {
                position: relative;
                margin: 0 2rem 0 1rem;

                >a,
                >a:link,
                >a:visited {
                    display: block;
                }

                >a {
                    font-size: 1.125rem;

                    &:hover {
                        color: $blue;
                        transition: all 0.3s ease;
                        border-image-source: linear-gradient(to left, $red, $blue);
                        border: 10px solid;
                        border-image-slice: 1;
                        border-width: 5px;
                        border-left: 0;
                        border-right: 0;
                        border-top: 0;
                    }
                }

                &.has-submenu {
                    margin: 0;

                    >label {
                        cursor: pointer;
                        display: block;
                        font-size: 1.125rem;
                        position: relative;
                        padding: 0 2rem 0 1rem;
                    }
                }

                &.close-nav {
                    display: none;
                }

                &.active {

                    >a,
                    >a:link,
                    >a:visited,
                    >label {
                        background: lighten(black, 90);
                    }
                }
            }
        }

        .menu-caret {
            border-bottom: 0.4rem solid transparent;
            border-top: 0.4rem solid transparent;
            border-left: 0.4rem solid $blue;
            display: inline-block;
            line-height: 0;
            position: absolute;
            right: 0.5rem;
            top: 0.625rem;
            transform-origin: 0 500;
            transition: .2s;
        }

        .submenu {
            display: flex;
            background-color: $panelGray;
            flex-direction: column;
            margin: 0.5rem 0;
            padding: 0;
            opacity: 0;
            position: absolute;
            transition: 0.2s;
            visibility: hidden;
            transform: translateY(-5rem);
            white-space: nowrap;
            z-index: 2;

            >li {
                position: relative;

                >a,
                >a:link,
                >a:visited {
                    color: white;
                    display: block;
                    font-size: 1.125rem;
                    padding: 0.5rem 1rem;

                    img {
                        max-width: 20px;
                        margin-right: 0.625rem;
                    }
                }

                >a:hover {
                    color: $blue;
                }
            }
        }

        .check-toggle {
            display: none;

            &:checked~label {
                background: $panelGray;
            }

            &:checked~.menu-caret {
                transform: rotate(90deg);
            }

            &:checked~.submenu {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
            }
        }

        .hamburger-menu,
        .menu-overlay {
            display: none;
        }

        .right-nav {
            display: flex;

            >li {
                align-items: center;
                display: flex;
                margin: 0 0.4rem 0 0;
                position: relative;
            }
        }

        .avatar {
            border: 0.1rem solid rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.4);
            cursor: pointer;
            display: inline-block;
            height: 3.6rem;
            margin: 0 0 0 1rem;
            overflow: hidden;
            width: 3.6rem;

            img {
                width: 100%;
            }
        }

        .account-menu {
            border-top: 0.3rem solid lighten(black, 80);
            opacity: 0;
            position: absolute;
            right: 0;
            top: 5rem;
            transition: 0.2s;
            transform: translateY(-4rem);
            visibility: hidden;
            z-index: 1;

            li {
                margin: 0 0 0.1rem;

                a,
                a:link,
                a:visited {
                    background: lighten(black, 96);
                    display: block;
                    padding: 0.5rem 1.5rem;
                }

                a:hover {
                    background: lighten(black, 90);
                }
            }

            &::before {
                border-bottom: 0.8rem solid lighten(black, 80);
                border-left: 0.6rem solid transparent;
                border-right: 0.6rem solid transparent;
                content: "";
                position: absolute;
                top: -1rem;
                right: 1rem;
            }
        }

        .check-toggle {
            &:checked~.account-menu {
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
            }
        }

        @media (max-width: $breakpoint-up-md) {

            .hamburger-menu,
            .menu-overlay {
                display: block;
            }

            .menu-overlay {
                bottom: 0;
                left: 0;
                opacity: 0;
                position: fixed;
                right: 0;
                transition: 0.2s;
                transform: translateX(30%);
                visibility: hidden;
                top: 0;
                z-index: 2;
            }

            .hamburger-menu {
                background: repeating-linear-gradient(0deg, transparent 0, transparent 0.4rem, white 0.4rem, white 0.7rem);
                cursor: pointer;
                display: inline-block;
                height: 2.2rem;
                width: 2.6rem;
            }

            .main-nav-holder {
                background: $panelGray;
                bottom: 0;
                left: 0;
                overflow: hidden;
                position: fixed;
                top: 0;
                transform: translateX(-100%);
                width: 25rem;
                z-index: 3;
            }

            .main-nav {
                bottom: 0;
                display: block;
                left: 0;
                overflow-y: scroll;
                position: absolute;
                right: -1.5rem;
                top: 0;

                >li {
                    border: none;
                    border-bottom: 1px solid white;
                    margin: 0;
                    padding: 0.5rem;

                    &.close-nav {
                        display: block;
                        text-align: right;

                        >label {
                            cursor: pointer;
                            display: block;
                            height: 4rem;
                            position: relative;

                            &::before,
                            &::after {
                                background: lighten(black, 50);
                                content: "";
                                height: 1.8rem;
                                left: 50%;
                                position: absolute;
                                top: 1rem;
                                transform: translateX(-50%);
                                width: 0.2rem;
                            }

                            &::before {
                                transform: rotate(-45deg);
                            }

                            &::after {
                                transform: rotate(45deg);
                            }

                            &:hover {

                                &::before,
                                &::after {
                                    background: lighten(black, 20);
                                }
                            }
                        }
                    }
                }
            }

            .has-submenu {
                .menu-caret {
                    left: 0.5rem;
                    top: 1rem;
                }
            }

            .submenu {
                label {
                    padding: 0;
                }

                li {
                    &::after {
                        display: none;
                    }
                }
            }

            .check-toggle {
                &:checked~.main-nav-holder {
                    opacity: 1;
                    transform: translateX(0);
                    visibility: visible;
                    z-index: 100;
                }

                &:checked~.menu-overlay {
                    background: rgba(0, 0, 0, 0.3);
                    opacity: 1;
                    transform: translateX(0);
                    visibility: visible;
                }

                &:checked~.submenu {
                    height: auto;
                }
            }

            .submenu {
                display: block;
                height: 0;
                margin: 0;
                padding: 0 0 0 1rem;
                position: static;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-header {

        .container-nav {
            align-items: center;
            gap: initial;
            justify-content: space-between;
            padding: 3rem 2rem 0;

            .logo {
                img {
                    width: 120px;

                }
            }

            .nav {
                display: none;
            }
        }
    }
}

.block-top {
    .wrap {
        display: flex;
        height: 600px;
        align-items: center;

        .container-left {
            width: 50%;
            padding-top: 8rem;
        }
    }

    .top-imgs {
        position: relative;
        margin-top: -32rem;
    }

    .top-img {
        position: absolute;

        img {
            width: 12vw;
            max-width: initial;
        }
    }

    .top-grid {
        left: -7vw;
        top: -2vw;

        svg {
            width: 68vw;
        }

        img {
            -webkit-transform: translate3d(0, 0, 0);
            -webkit-backface-visibility: hidden;
            -webkit-perspective: 1000;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000;
        }
    }


    .shield {
        left: 8vw;
        top: 12vw;
        z-index: 50;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000;
        will-change: transform;
        z-index: 60;

        img {
            width: 12vw;
        }

        .dots {
            top: 4vw;
            left: 13vw;
            opacity: 0.5;
            z-index: 50;
            will-change: transform;
        }
    }

    .person {
        left: 13.5vw;
        top: 2.3vw;
    }

    .cube {
        left: 30vw;
        top: -3vw;
    }

    .cube img {
        width: 9vw;
    }

    .laptop {
        left: 21vw;
        top: 11.8vw;
    }

    .card {
        left: 30vw;
        top: 22vw;
    }

    .card img {
        width: 10vw;
    }

    .cash {
        left: 35.5vw;
        top: 9.5vw;
    }

    .cash img {
        width: 10.8vw;
    }

    .dot {
        position: absolute;
        top: 21.5vw;
        left: -7.25vw;
        will-change: transform;
    }

    .dot2 {
        position: absolute;
        top: 0.2vw;
        left: 14.75vw;
        will-change: transform;
    }

    .dot3 {
        position: absolute;
        right: -43vw;
        top: 6.8vw;
        will-change: transform;
    }

    .dot img,
    .dot2 img,
    .dot3 img {
        width: 0.8vw;
        height: 15px;
    }

    .dot2 img {
        max-width: initial;
    }

    .typing span {
        /*   padding: 5% 0 2% 0; */
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    .cursor {
        opacity: 1;
        font-weight: 100;
        -webkit-animation: blink 0.7s infinite;
        -moz-animation: blink 0.7s infinite;
        -ms-animation: blink 0.7s infinite;
        -o-animation: blink 0.7s infinite;
        animation: blink 0.7s infinite;
    }

    h1.typing {
        position: relative;
    }

    .cursor {
        position: absolute;
        /* top: 0.2vw; */
        color: #c5334f;
        width: 8px;
        display: inline-flex;
        overflow: hidden;
    }
}

@media screen and (min-width: $breakpoint-down-xxl) {
    .block-top {
        .top-img {
            img {
                width: 230px;
            }
        }

        .top-grid {
            left: -134px;
            top: -38px;

            svg {
                width: 1306px;
            }
        }


        .shield {
            left: 154px;
            top: 230px;

            img {
                width: 230px;
            }

            .dots {
                top: 77px;
                left: 250px;
            }
        }

        .person {
            left: 259px;
            top: 44px;
        }

        .cube {
            left: 576px;
            top: -58px;
        }

        .cube img {
            width: 173px;
        }

        .laptop {
            left: 403px;
            top: 227px;
        }

        .card {
            left: 576px;
            top: 422px;
        }

        .card img {
            width: 192px;
        }

        .cash {
            left: 682px;
            top: 182px;
        }

        .cash img {
            width: 207px;
        }

        .dot {
            top: 413px;
            left: -139px;
        }

        .dot2 {
            top: 4px;
            left: 283px;
        }

        .dot3 {
            right: -826px;
            top: 131px;
        }

        .dot img,
        .dot2 img,
        .dot3 img {
            width: 15px;
        }
    }
}

@media screen and (max-width: $breakpoint-up-xl) {
    .block-top {
        padding-top: 6rem;

        .wrap {
            .container-left {
                padding-top: 1rem;
            }
        }
    }
}



@media screen and (max-width: $breakpoint-up-md) {
    .block-top {
        .wrap {
            height: 400px;
            padding-top: 2rem;
            flex-direction: column-reverse;

            .container-left {
                width: 100%;
                padding-top: 4rem;
                text-align: center;
            }

            .top-imgs {
                display: none;
                width: 100%;
                height: 300px;
            }
        }

    }
}

.block-what {
    background: #000;
    clip-path: polygon(0 30%, 100% 0, 100% 100%, 0% 100%);
    padding: 28rem 0 10rem;

    .conatiner-heading {
        text-align: center;
        margin-top: -4rem;
    }

    .container-grid {
        display: flex;
        gap: 5rem;
        flex-direction: column;
        background-image: url('../../assets/images/graphic-grid.png');
        background-position: top center;
        padding-top: 15rem;
        background-repeat: no-repeat;

        .grid-row {
            display: flex;
            gap: 400px;
            justify-content: center;

            .item {
                min-width: 390px;

                &:first-child {
                    text-align: right;
                }

                &.center {
                    text-align: center;
                }
            }


            .number {
                color: $red;
                font-family: $fontInter;
                font-size: 3.5rem;
                padding-bottom: 1rem;
            }
        }
    }
}

/* wall animation */

.block-what {
    position: relative;

    .container-grid {
        background-image: url(../../assets/images/network-withoutwall.png);
    }

    .block-wall {
        position: absolute;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        top: 45rem;
    }

    .redBlocks {
        will-change: transform;
    }
}

@media screen and (max-width: $breakpoint-up-xl) {
    .block-what {
        clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 100%);
        padding: 18rem 0 10rem;
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-what {
        .block-wall {
            width: 50%;
            transform: translateX(-50%);
        }

        .container-grid {
            gap: 0;

            .grid-row {
                flex-direction: column;
                gap: 0;
                justify-content: flex-end;
                align-items: flex-end;

                .item {
                    min-width: auto;
                    width: 100%;
                    padding-left: 28% !important;
                    margin-bottom: 0.5rem;
                    text-align: left !important;
                }

                .number {
                    font-size: 2.5rem !important;
                    padding-bottom: 0.5rem !important;
                }

                p {
                    font-size: 1.25rem !important;
                }
            }
        }


    }
}


.block-chain {
    background-color: #000;
    background-image: url('../../assets/images/graphic-chain.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    padding-bottom: 5em;

    .wrap {
        background-color: rgba(156, 156, 255, .11);

        .container-solutions {
            display: flex;
            padding: 150px 20px;
            gap: 3rem;

            .container-left {
                width: 40%;
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            .container-grid {
                display: flex;
                flex-direction: column;
                gap: 4rem;
                width: 60%;

                .grid-row {
                    display: flex;
                    gap: 2.5rem;
                    width: 100%;

                    &:nth-child(2n) {
                        justify-content: flex-end;
                    }

                    .item {
                        background: $panelGray;
                        color: white;
                        padding: 0 2rem;
                        box-shadow: 4px 4px 25px 0px #00000080;
                        border-radius: 32px;
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                        justify-content: center;
                        height: 64px;
                        transition: all 0.4s ease;

                        img {
                            max-height: 2rem;
                        }

                        &:nth-child(2n) {
                            margin-top: 1rem;
                        }

                        &:hover {
                            background: $bgGray;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-chain {
        .wrap {
            .container-solutions {
                flex-direction: column;
                align-items: center;

                .container-left {
                    width: 100%;
                    text-align: center;
                    gap: 1rem;
                }

                .container-grid {
                    width: 100%;

                    .grid-row {
                        flex-direction: column;

                        .item {
                            width: fit-content;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }

        .container-about-us {
            padding: 6rem 0 3rem;

            .container-heading {
                max-width: 100%;
            }

            .timeline {
                &:before {
                    left: 10%;
                }
            }

            .entries {
                padding-left: 10% !important;
                gap: 2rem;

                .entry {
                    align-self: flex-start !important;
                    margin: 0 !important;
                    width: 100% !important;
                    text-align: left !important;

                    .number {
                        font-size: 2.5rem !important;
                        margin: 0 !important;

                        &:before {
                            left: -25px !important;
                            width: 0.8rem !important;
                            height: 0.8rem !important;
                        }
                    }
                }
            }

        }
    }
}

.block-about-us {
    background-color: $bgGray;
    padding: 6rem 0 3rem;

    .container-about {
        position: relative;
        min-height: 620px;

    }

    #wrap,
    #holder,
    #overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #holder {
        z-index: 1;
        opacity: 0.5;
    }

    #overlay {
        left: 0;
        right: 0;
        width: auto;
        z-index: 2;
        -webkit-box-shadow: inset 0 0 40px 30px #1a1a24;
        box-shadow: inset 0 0 40px 30px #1a1a24;
    }

    #wrap {
        z-index: 3;
    }

    .container-heading {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0 auto;
        max-width: 60%;
        padding-bottom: 5rem;
        text-align: center;
    }

    .timeline {
        position: relative;
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 50%;
            bottom: 0px;
            width: 1px;
            background-color: $blue;
        }
    }

    .entries {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 7rem;

        .entry {
            width: calc(50% - 40px);
            padding: 3rem 1.25rem 0;
            text-align: right;
            display: flex;
            flex-direction: column;

            &:not(:first-child) {
                margin-top: -60px;
            }

            .number {
                color: $red;
                font-size: 4rem;
                position: relative;
                font-weight: 800;
                line-height: 1.1;

                &:first-child {
                    margin-top: -30px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 1.25rem;
                    height: 1.25rem;
                    background-color: $red;
                    border-radius: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -70px;
                    z-index: 1000;
                }
            }

            &:nth-child(2n + 1) {
                align-self: flex-end;
                text-align: left;

                .number {
                    &:before {
                        left: -70px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-lg) {
    .block-about-us {
        .container-about {
            min-height: 550px;
        }

        .container-heading {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-about-us {
        padding: 6rem 0 3rem;

        .timeline {
            &:before {
                left: 10%;
            }
        }

        .entries {
            padding-left: 10% !important;
            gap: 2rem;

            .entry {
                align-self: flex-start !important;
                margin: 0 !important;
                width: 100% !important;
                text-align: left !important;

                .number {
                    font-size: 2.5rem !important;
                    margin: 0 !important;

                    &:before {
                        left: -25px !important;
                        width: 0.8rem !important;
                        height: 0.8rem !important;
                    }
                }
            }
        }

    }
}

@media screen and (max-width: $breakpoint-up-sm) {
    .block-about-us {
        .container-about {
            min-height: 600px;

            p {
                font-size: 1.125rem;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-xxs) {
    .block-about-us {
        .container-about {
            min-height: 800px;
        }
    }
}

@media screen and (max-width: $breakpoint-up-xxxs) {
    .block-about-us {
        .container-about {
            min-height: 900px;
        }
    }
}

.block-partners {
    background-color: #23232f;
    clip-path: polygon(0 0%, 100% 15%, 100% 100%, 0 100%);
    padding: 10rem 0 9rem;

    .container-heading {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0 auto;
        max-width: 50%;
        padding: 5rem 1rem;
        text-align: center;
    }

    .marquee-wrapper {
        text-align: center;
        overflow: hidden;
    }

    .marquee-inner span {
        float: left;
        width: 50%;
    }

    .marquee-wrapper .marquee-block {
        --total-marquee-items: 7;
        height: 150px;
        width: calc(248px * (var(--total-marquee-items)));
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        margin: 20px auto;
        padding: 30px 0;
    }

    .marquee-inner {
        display: block;
        width: 200%;
        position: absolute;
    }

    .marquee-inner.to-left {
        animation: marqueeLeft 40s linear infinite;
    }

    .marquee-inner.to-right {
        animation: marqueeRight 40s linear infinite;
    }

    .marquee-item {
        width: 228px;
        height: 100px;
        margin: 0 10px;
        float: left;
        transition: all .2s ease-out;
        border: solid 1px $darkRed;
        box-sizing: border-box;
        padding: 8px 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 80px;
        }
    }

    @keyframes marqueeLeft {
        0% {
            left: 0;
        }

        100% {
            left: -100%;
        }
    }

    @keyframes marqueeRight {
        0% {
            left: -100%;
        }

        100% {
            left: 0;
        }
    }

    .grid {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .row-partners {
            display: flex;
            gap: 1rem;
            justify-content: center;

            .item {
                width: 200px;
                height: 120px;
                border: solid 1px $darkRed;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-partners {
        clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 100%);
        padding: 3rem 0 9rem;

        .container-heading {
            max-width: 100%;
        }
    }
}

.block-conferences {
    // background-color: $bgBlue;
    // background-image: url('../../assets/images/graphic-conferences.png');
    // background-size: cover;
    // background-repeat: no-repeat;
    padding: 8rem 0 4rem;
    position: relative;
    // min-height: 520px;
    overflow: hidden;

    .container-heading {
        margin: 0 auto;
        max-width: 50%;
        padding-bottom: 5rem;
        text-align: center;
    }

    .container-slider {
        max-width: 1000px !important;
        margin: 0 auto;

        .item-wrap {
            display: flex;
            gap: 3rem;


            .container-left {
                width: 50%;
                display: flex;
                justify-content: flex-end;
            }

            .container-right {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .title {
                    font-size: 2.5rem;
                    font-family: $fontInter;
                    font-weight: 700;
                    padding-bottom: 1.5rem;
                }

                .description {
                    line-height: 1.5;
                    padding-bottom: 1.5rem;
                }

                .action {
                    a {
                        position: relative;

                        font-size: 1.125rem;
                        padding: 0.75rem 1.25rem;
                        display: inline-block;

                        &:before {
                            content: "";
                            position: absolute;
                            inset: 0;
                            border-radius: 50px;
                            padding: 2px;
                            background: linear-gradient(270deg, #00B7C6, #C5334F);
                            -webkit-mask:
                                linear-gradient(#fff 0 0) content-box,
                                linear-gradient(#fff 0 0);
                            -webkit-mask-composite: xor;
                            mask-composite: exclude;
                        }
                    }

                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-conferences {
        .container-heading {
            width: 100%;
            max-width: none !important;
        }

        .container-slider {
            .item-wrap {
                .container-left {
                    display: none;
                }

                .container-right {
                    width: 100% !important;
                    text-align: center;
                }
            }
        }
    }
}

.block-showcase {
    padding-bottom: 10rem;

    .wrap {
        max-width: 800px;
        margin: 0 auto;
    }

    .container-heading {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0 auto;
        max-width: 50%;
        padding: 10rem 1rem 2rem;
        text-align: center;
    }

    .container-list {
        .wrap-highlight {
            display: flex;
            gap: 1rem;
            padding-bottom: 1rem;

            .flex-left {
                display: flex;
                width: calc(100% * (2 / 3));

                .item {
                    border-top: 3px solid $blue;
                }
            }

            .flex-right {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                width: 32%;

                .item:first-child {
                    border-right: 3px solid $red;
                }

                .item:last-child {
                    border-right: 3px solid white;
                }

                >div {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
        }

        .wrap-items {
            display: flex;
            gap: 1rem;
            padding-bottom: 1rem;

            &.alternate-border {
                .item:nth-child(2n) {
                    border-right: 3px solid $red;
                }

                .item:nth-child(2n + 1) {
                    border-right: 3px solid white;
                }
            }

            .item {
                width: calc(100% * (1 / 3));
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        .item {
            background-color: black;
            padding: 1.5rem 2rem;
            flex: 1;

            .title {
                font-family: $fontInter;
                font-size: 1.25rem;
                padding-bottom: 3rem;
            }

            .description {
                font-size: 1rem;
                line-height: 1.5;
                padding-bottom: 2rem;
            }

            .link {
                font-size: 1rem;

                a {
                    color: $blue;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-showcase {
        .container-list {
            .wrap-highlight {
                flex-direction: column;

                .flex-left,
                .flex-right {
                    width: 100%;
                }
            }

            .wrap-items {
                display: flex;
                flex-direction: column;
            }

            .item {
                width: 100% !important;
                border: 0 !important;
            }
        }
    }
}

.block-jobs {
    margin-bottom: 5rem;

    .container {
        background-image: url('../../assets/images/graphic-jobs.png');
        background-position: left center;
        background-repeat: no-repeat;
        background-size: 70%;
        padding: 10rem 0;

        .wrap {
            display: flex;
            gap: 1rem;

            .container-info {
                width: 100%;
                min-height: 400px;
                padding: 2rem;

                display: flex;
                gap: 1rem;
                flex-direction: column;
                justify-content: center;
            }

            .container-jobs {
                width: 100%;
                display: flex;
                gap: 1rem;
                flex-direction: column;
                justify-content: center;

                .item {
                    background-color: $panelGray;
                    padding: 1rem 1.25rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 0.5rem;
                    transition: all 0.3s ease;

                    &:hover {
                        background-color: $bgGray;
                    }

                    .title {
                        font-size: 2rem;
                        font-weight: 700;
                    }

                    .location {
                        font-size: 1.25rem;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-jobs {
        .container {
            background-position: 0 2rem;
            background-size: 100%;
            padding: 5rem 0;

            .wrap {
                flex-direction: column;

                .container-info {
                    min-height: 300px;
                }
            }
        }
    }
}


.block-contact {
    .wrap {
        background: $bgDarkRed;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
        margin: 0 auto;
        max-width: 1000px;
        padding: 1.375rem;
        position: relative;
        z-index: 10;
        margin-top: -5rem;

        .container-contact {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
            background: $red;
            min-height: 400px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 60px;
            box-sizing: border-box;
            gap: 50px;

            .container-left {
                width: 35%;
            }

            .divider {
                background-color: $green;
                width: 1px;
                min-height: 150px;
                height: 100%;
            }

            .container-right {
                width: 35%;
                font-weight: 300;
                line-height: 1.5;

                span {
                    display: inline-block;
                    font-weight: 400;
                    width: 100px;
                }
            }
        }
    }

    .container-map {
        background-image: url(../../assets/images/graphic-map.png);
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        min-height: 800px;
        max-width: 880px;
        margin: -250px auto 0;
        position: relative;
        z-index: 8;

        .pulsating-circle {
            position: absolute;
            left: 44%;
            top: 56%;
            transform: translateX(-50%) translateY(-50%);
            width: 60px;
            height: 60px;

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                background-image: url(../../assets/images/pin.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: contain;
                border-radius: 15px;
                box-shadow: 0 0 8px rgba(0, 0, 0, .3);
                animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
            }
        }

    }

    .disclamer {
        font-size: 0.75rem;
        font-weight: 300;
        line-height: 1.3;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 1rem 1rem;
        text-align: center;
    }

    .social {
        display: flex;
        gap: 1rem;
        justify-content: center;
        padding-bottom: 2rem;

        img {
            max-width: 2.5rem;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-contact {
        .wrap {
            margin-top: 0;
            padding: 1.375rem 0;

            .container-contact {
                flex-direction: column !important;
                gap: 1.5rem;

                .container-left {
                    width: 70%;

                    p {
                        font-size: 1.2rem;
                        margin-bottom: 0 !important;
                    }
                }

                .divider {
                    height: 1px !important;
                    min-height: auto !important;
                    width: 80% !important;
                }

                .container-right {
                    width: 70%;
                    font-size: 1.1rem;
                }
            }

        }

        .container-map {
            min-height: 460px;
            margin: -150px auto 0;

            .pulsating-circle {
                width: 40px;
            }
        }
    }
}

@keyframes pulse-ring {
    0% {
        transform: scale(.33);
    }

    80%,
    100% {
        opacity: 0;
    }
}

@keyframes pulse-dot {
    0% {
        transform: scale(.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(.8);
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.block-page {
    padding-top: 9rem;

    h2 {
        padding: 2rem 0;
    }

    ul {
        list-style: none;
        font-weight: 300;
        margin-bottom: 2rem;
        padding-left: 0;

        li {
            font-size: 1rem;
            padding-left: 1.5rem;
            padding-bottom: 0.5rem;
            position: relative;

            &:before {
                content: '';
                width: 14px;
                height: 14px;
                position: absolute;
                background-image: url('../../assets/images/bullet.png');
                background-size: cover;
                background-position: center;
                left: 0;
                top: 0.5rem;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .container-full {
        font-size: 1.125rem;
        margin: 0 auto;
        max-width: 1000px;
        padding-bottom: 5rem;
        width: 100%;
    }

    .container-top {
        display: flex;
        gap: 4rem;

        .container-left {
            font-size: 1.125rem;
            width: 60%;
        }

        .container-right {
            img {
                max-width: 500px;
            }
        }
    }

    .container-accordion {
        padding-bottom: 3rem;

        .accordion {
            color: $red;
            cursor: pointer;
            padding: 2rem 1rem;
            width: 100%;
            border-top: solid 1px $red;
            border-bottom: solid 1px $red;
            text-align: left;
            outline: none;
            font-size: 2rem;
            transition: 0.4s;
            background-color: transparent;
            border-radius: 0;

            &:first-child {
                border-top-width: 2px;
            }

            &:last-child {
                border-bottom-width: 2px;
            }
        }

        .active,
        .accordion:hover {
            border-color: $blue;
            color: $blue;
        }

        .accordion:after {
            content: '\2193';
            color: $red;
            font-size: 2.25rem;
            font-weight: bold;
            float: left;
            margin-right: 1rem;
            transform: translate(0, -2px);
        }

        .active:after {
            content: "\2191";
            color: $blue;
        }

        .panel {
            background-color: $panelGray;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.4s ease;

            .content {
                font-size: 1.125rem;
                padding: 2.25rem 2.5rem;

                p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media screen and (max-width: $breakpoint-up-md) {
    .block-page {
        padding-top: 0;

        h1,
        h2 {
            font-size: 2rem;
        }

        .container-top {
            flex-direction: column-reverse;
            gap: 1rem;
            padding-top: 1rem;

            .container-left {
                width: 100%;
            }

            .container-right {
                text-align: center;

                img {
                    margin: 0 auto;
                    max-width: 200px;
                }
            }
        }

        .container-accordion {
            .accordion {
                font-size: 1.5rem;
                padding: 1.5rem 1rem;
            }

            .accordion:after {
                font-size: 1.25rem;
                margin-right: 1rem;
                transform: translate(0, 3px);
            }
        }
    }
}